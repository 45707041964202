import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'coffee',
  formId: 'UK%20general%20coffee',
  title: 'Kaffe',
  subTitle: 'Skru op for duften af kaffe på kontoret!',
  theme: 'sunrise-coffee',
  icon: '/icons/coffee.svg',
  image: '/assets/images/sunrise/coffee_supermercat.png',
  sections: [
    {
      sectionId: 'hero',
      label: 'Kaffe',
      title: 'Skru op for duften af kaffe på kontoret!',
      description:
        'Kan vi blive enige om, at livet er for kort til dårlig kaffe? Vi kan hjælpe, hvad enten du er til den frugtagtige, klassiske, ‘stik-mig-en-kaffe-så-jeg-kan-blive-menneske-igen’ eller lette aroma. Vi har også maskiner, der passer til.',
      image: '/assets/images/sunrise/coffee_supermercat.png',
      icon: '/icons/coffee.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Når bønnen (og maskinen) er god er alting godt',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/coffee/service_1.svg',
          title: 'Kaffebønner',
          description:
            'Vi har kaffe til enhver smag og pengepung. Det er dig der bestemmer om I er til Cortado, filter eller de smarte hvide.',
        },
        {
          icon: '/assets/images/services/coffee/service_2.svg',
          title: 'Kaffemaskiner',
          description:
            'De to fødders lov siger at vi mødes ved kaffemaskinen. Vælg mellem et hav af varianter fra mormors filterkaffe til feinsmeckerrumskibe.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/coffee/journey_1.svg',
          title: 'Fortæl os hvad I kan lide',
          description: 'Vi har både maskiner og bønner gallore, så fold fantasien ud.',
        },
        {
          icon: '/assets/images/services/coffee/journey_2.svg',
          title: 'Vi sender dig et tilbud',
          description: 'Vi gør os umage med at tilbudet er detaljeret og giver dig overblik over jeres kaffeudgift.',
        },
        {
          icon: '/assets/images/services/coffee/journey_3.svg',
          title: 'Accepter!',
          description: 'Sådan. Nu har I in-house tankstation.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker vore kunder kaffe fra Good Monday?',
      description: '',
      image: '/assets/images/services/coffee/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-eye-outline',
          title: 'Nemt overblik',
          description: 'Få overblik over jeres forbrug og hold øje med hvad dine kollegaer har brug for.',
        },
        {
          icon: 'eva eva-sync-outline',
          title: 'Automatisk genbestilling af bønner',
          description: 'Kaffebønner kan være en del af dit forbrugsabonnement.',
        },
        {
          icon: 'eva eva-edit-2-outline',
          title: 'Du kan skifte mellem bønner når du vil',
          description: 'Vi har tonsvis af forskellige bønner, så slip fantasien løs.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at Good Monday serverer omtrent 5000 kopper kaffe hver eneste dag?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
